import axios from 'axios';

const state = {
    user: null,
    org: null,
    gdpr: null,
    preferences: null,
    favouriteProjects: null,
    recentProjects: null,
    dashboardLayout: null,
    error: null,
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    navTab: null,
    subscriptions: [],
    privacy_policy: null,
}

const getters = {
    user: state => state.user,
    org: state => state.org,
    gdpr: state => state.gdpr,
    preferences: state => state.preferences,
    favouriteProjects: state => state.favouriteProjects,
    recentProjects: state => state.recentProjects,
    dashboardLayout: state => state.dashboardLayout,
    recentProjectsList: state => Object.keys(state.preferences.data.projects).sort(),
    layout: state => {
        if(state.preferences) return state.preferences.data.layout
    },
    error: state => state.error,
    token: state => state.token,
    navTab: state => state.navTab,
    subscriptions: state => state.subscriptions,
    subscriptionIDs: state => state.subscriptions.map(s => { return s.project_id.toString() } ),
    privacy_policy: state => state.privacy_policy,
}

const mutations = {
    setState: (state, data) => {
        state.user = data.user;
        state.org = data.org;
        state.gdpr = data.gdpr;
        state.preferences = data.preferences;
        state.favouriteProjects = data.preferences.data.projects;
        state.recentProjects = data.recent_projects;
        state.dashboardLayout = data.preferences.data.layout?.recent_projects
    },

    setRecentProjects: (state, data) => state.recentProjects = data.recent_projects,
    setDashboardLayout: (state, data) => state.dashboardLayout = data.preferences.data.layout.recent_projects,
    setItem: (state, data) => {
        Object.keys(data).forEach(key => {
            state[key] = data[key];
        });
    },
    setError: (state, error) => state.error = error,
    setNavTab: (state, tab) => state.navTab = tab,
    setSubscriptions: (state, subscriptions) => state.subscriptions = subscriptions,
    setPrivacyPolicy: (state, policy) => state.privacy_policy = policy,
}

const actions = {
    session({commit},params) {
        commit('setItem', params);
    },
    fetchSession({commit}) {
        axios.get('/settings.json').then(res => {
            commit('setState', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    fetchPrivacyPolicy({commit}) {
        axios.get('/privacy_policy').then(res => {
            commit('setPrivacyPolicy', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    deleteRecentProject({commit, state}, id) {
        axios.delete(`/projects/${id}/preference.json`, {headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': state.token}}).then(res => {
            commit('setRecentProjects', res.data)
            commit('setItem',res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    pinRecentProject({commit, state}, id) {
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
        };
        axios.post(`/settings/pin/${id}.json`, params).then(res => {
            if(res.response) throw res.response.data;
            commit('setRecentProjects',res.data);
            commit('setItem',res.data);
        }).catch(error => {
            commit('setError', error);
        })
    },
    setLayout({commit}, params) {
        params['authenticity_token'] = state.token;
        params['charset'] = 'UTF-8';
        axios.post('/settings/layout.json', params).then(res => {
            if(res.response) throw res.response.data;
            commit('setItem',res.data);
            commit('setDashboardLayout',res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    setNavTab({commit}, tab) {
        commit('setNavTab', tab);
    },
    fetchSubscriptions({commit}) {
        axios.get(`/subscriptions.json`).then(res => {
            commit('setSubscriptions', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    subscribeProject({commit, state}, {project_id, notify_type = null}) {
        const params = {
            "accept-charset": "UTF-8",
            "authenticity_token": state.token,
            project_id: project_id,
        };
        if(notify_type !== null) params.notify_type = notify_type;

        axios.post('/subscriptions.json', params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.response) throw res.response.data;
            commit('setSubscriptions', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        });
    },
    unsubscribeProject({commit, state}, project_id) {
        axios.delete(`/subscriptions/${project_id}.json`, {headers: { 'Content-Type': 'application/json','X-CSRF-Token':state.token}}).then(res => {
            if(res.response) throw res.response.data;
            commit('setSubscriptions', res.data);
        }).catch((error) => {
            commit('setError', error.toString());
        });
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
}