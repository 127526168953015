import axios from 'axios';

const state = {
    projectSelectedFile:null,
    allProjects: null,
    archivedProjects: null,
    myProjects: null,
    orgProjects: null,
    project: null,
    projectSearchResults: [],
    folders: null,
    folderProperties: null,
    files: null,
    timespan: 'All',
    selectedFiles: [],
    missingFiles: [],
    currentFolder: null,
    breadcrumbs: null,
    fileCart: [],
    folderCart: [],
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    postManager: null,
    loadingFiles: null,
    globalMessage: null,
    filterApplied: false,
}

const getters = {
    projectSelectedFile: state => state.projectSelectedFile,
    allProjects: state => state.allProjects,
    archivedProjects: state => state.archivedProjects,
    myProjects: state => state.myProjects,
    orgProjects: state => state.orgProjects,
    project: state => state.project,
    projectSearchResults: state => state.projectSearchResults,
    files: state => state.files,
    timespan: state => state.timespan,
    selectedFiles: state => state.selectedFiles,
    missingFiles: state => state.missingFiles,
    breadcrumbs: state => state.breadcrumbs,
    currentFolder: state => state.currentFolder,
    folders: state => state.folders,
    folder: state => id => state.folders.filter(f => f.id === id)[0],
    folderProperties: state => state.folderProperties,
    folderByPath: state => path => state.folders.filter(f => f.path === path)[0],
    fileCart: state => state.fileCart,
    folderCart: state => state.folderCart,
    loadingFiles: state => state.loadingFiles,
    globalMessage: state => state.globalMessage,
    filterApplied: state => state.filterApplied,
}

const mutations = {
    setProjectSelectedFile: (state, data) => state.projectSelectedFile = data,
    setAllProjects: (state, data) => state.allProjects = data,
    setArchivedProjects: (state, data) => state.archivedProjects = data,
    setMyProjects: (state, data) => state.myProjects = data,
    setOrgProjects: (state, data) => state.orgProjects = data,
    setProject: (state, project) => state.project = project,
    setProjectSearchResults: (state, results) => state.projectSearchResults = results,
    setFolders: (state, folders) => state.folders = folders,
    setFolderProperties: (state, properties) => state.folderProperties = properties,
    appendFolder: (state, folder) => {
        if(state.folders.filter(f => f.id === folder.id)[0] === undefined) state.folders.push(folder)
    },
    replaceFolders: (state, folders) => {
        folders.forEach(folder => {
            for (let i = 0; i < state.folders.length; i++) {
                if (state.folders[i].id === folder.id) {
                    state.folders[i].name = folder.name;
                    state.folders[i].path = folder.path;
                    state.folders[i].weight = folder.weight;
                    state.folders[i].archive = folder.archive;
                    state.folders[i].updated_at = folder.updated_at;
                    break;
                }
            }
        });
    },
    removeFolder: (state, id) => state.folders = state.folders.filter(folder => folder.id !== id),
    setCurrentFolder: (state, folder) => state.currentFolder = folder,
    resetCurrentFolder: (state) => state.currentFolder = null,
    resetFolderProperties: (state) => state.folderProperties = null,
    addBreadcrumbs: (state, item) => state.breadcrumbs.push(item),
    resetBreadcrumbs: (state) => state.breadcrumbs = [],
    setFiles: (state, files) => state.files = files,
    setTimespan: (state, timespan) => state.timespan = timespan,
    addNewFile: (state, file) => state.files.push(file),
    updateFile: (state, file) => state.files.forEach((f,i) => { if(f.id === file.id) {
        state.files[i].filename = file.filename;
        state.files[i].updated_at = file.updated_at;
    } } ),
    /* removeNewFile - avoid name conflict */
    removeNewFile: (state, id) => state.files = state.files.filter(file => file.id !== id),
    addSelectedFile: (state, id) => {
        state.selectedFiles.push(id)
        state.selectedFiles = [...new Set(state.selectedFiles)]
    },
    removeSelectedFile: (state, ids) => state.selectedFiles = state.selectedFiles.filter(selected => !ids.includes(selected)),
    addMissingFile: (state, id) => {
        state.missingFiles.push(id)
        state.missingFiles = [...new Set(state.missingFiles)]
    },
    removeMissingFile: (state, id) => state.missingFiles = state.missingFiles.filter(missing => missing !== id),
    resetSelection: state => {
        state.selectedFiles = []
        state.missingFiles = []
    },
    /* file cart */
    addToFileCart: (state, id) => {
        const file = state.files.find(f => f.id === id);
        if(file !== undefined) state.fileCart.push(file);
        state.fileCart = [...new Set(state.fileCart)];
    },
    removeFromFileCart: (state, id) => {
        state.fileCart = state.fileCart.filter(f => f.id !== id);
        state.selectedFiles = state.selectedFiles.filter(selected => selected !== id);
    },
    resetFileCart: state => state.fileCart = [],
    /* folder cart */
    addToFolderCart: (state, id) => {
        const folder = state.folders.find(f => f.id === id);
        if(folder !== undefined) state.folderCart.push(folder);
        state.folderCart = [...new Set(state.folderCart)];
    },
    removeFromFolderCart: (state, id) => state.folderCart = state.folderCart.filter(f => f.id !== id),
    resetFolderCart: state => state.folderCart = [],
    syncUserCart: (state, data) => {
        if(data.files !== undefined) state.fileCart = data.files;
        if(data.folders !== undefined) state.folderCart = data.folders;
    },
    postCart: (state, type) => {
        const cart = (type === 'file_cart') ? state.fileCart : state.folderCart;
        const ids = cart.map(f => f.id);
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            type: type,
            id: ids,
        };
        if(state.postManager !== null) clearTimeout(state.postManager);
        state.postManager = setTimeout(() => {
            axios.post('/cart.json', params, {headers: {'Content-Type': 'application/json'}}).then(res => {
                state.postManager = null;
            });
        },1000);
    },
    loadingFilesToggle: (state, toggle) => state.loadingFiles = toggle,
    updateFileThumbnails: state => {
        state.files.forEach(file => {
            if(state.selectedFiles.indexOf(file.id) > -1)  file.update_thumbnail = Date.now();
        })
    },
    setGlobalMessage: (state, message) => state.globalMessage = message,
    setFilterApplied: (state, data) => state.filterApplied = data,
}

const actions = {
    fetchAllProjects({commit}) {
        axios.get(`/projects.json`).then(res => {
            commit('setAllProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchArchivedProjects({commit}) {
        axios.get('/projects.json?archive').then(res => {
            commit('setArchivedProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchMyProjects({commit}, id) {
        axios.get(`/users/${id}/user_projects.json`).then(res => {
            commit('setMyProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchOrgProjects({commit}, id) {
        axios.get(`/addressbook/${id}/org_projects.json`).then(res => {
            commit('setOrgProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchProject({commit}, id) {
        axios.get(`/projects/${id}.json`).then(res => {

            const {folders, project} = res.data;

            commit('setFolders', folders);
            commit('setProject', project);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    fetchProjectsList({commit}, params) {
        params['authenticity_token'] = state.token;
        params['charset'] = 'UTF-8';
        axios.post(`/projects.json`, params).then(res => {
            if(res.response) throw res.response.data;
            commit('setProjectSearchResults', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchLabProjects({commit}, archive = false){
        axios.get(`/lab_projects.json?archive=${archive}`).then(res => {
            commit('setMyProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    setProjectSearchResults({commit}, results) {
        commit('setProjectSearchResults', results);
    },

    fetchFolder({commit}, project_id) {
        axios.get(`/project/${project_id}/folders.json`).then(res => {
            commit('setFolders', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    setFolders({commit}, folders) {
        commit('setFolders', folders);
    },
    appendFolder({commit}, folder) {
        commit('appendFolder',folder);
    },
    updateFolder({commit}, folder) {
        commit('replaceFolders',[folder]);
        axios.get(`/project/${folder.project_id}/folders.json`).then(res => {
            commit('setFolders', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    deleteFolder({commit}, id) {
        commit('removeFolder', id)
    },
    replaceFolders({commit}, folders) {
        commit('replaceFolders', folders);
    },
    updateCurrentFolder({commit}, folder) {
        commit('setCurrentFolder', folder);
    },
    resetCurrentFolder({commit}) {
        commit('resetCurrentFolder');
    },
    addBreadcrumbs({commit, state}, item) {
        if(item === null || item === undefined) {
            commit('resetBreadcrumbs');
            commit('addBreadcrumbs', {text: 'Projects', disabled: false, to: {name: 'projects'}});
        } else {
            let match = 0;
            state.breadcrumbs.forEach((breadcrumb, index) => {
                if(breadcrumb.to === item.path) match = index + 1;
            });

            if (!match) {
                commit('addBreadcrumbs',{text: item.name, disabled: item.disabled, to: item.path});
            } else {
                const breadcrumbs = state.breadcrumbs.slice(0,match);
                commit('resetBreadcrumbs');
                breadcrumbs.forEach(breadcrumb => {
                    commit('addBreadcrumbs',breadcrumb);
                })
            }
        }
    },
    async fetchFiles({commit, state}, folder_id) {
        try {
            const res = await axios.get(`/files/${folder_id}.json?timespan=${encodeURIComponent(state.timespan)}`)

            commit('setFiles', res.data);
            commit('loadingFilesToggle', false);

            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },
    setFiles({commit}, files) {
        commit('setFiles', files);
    },
    setTimespan({commit}, timespan) {
        commit('setTimespan', timespan);
        if(timespan !== 'All') commit('setAlertResponse', { type: 'error', text: `Filter: content restricted to files modified in the last ${timespan}`})
    },
    setGlobalMessage({commit, state}, message) {
        if(message !== null && message !== undefined) return commit('setAlertResponse', { type: 'error', text: message});
        if(state.timespan !== 'All') return commit('setAlertResponse', { type: 'error', text:`Filter: content restricted to files modified in the last ${state.timespan}`});
    },
    addNewFile({commit}, file) { commit('addNewFile', file) },
    updateFile({commit}, file) { commit('updateFile', file) },
    removeSelectedFiles({commit, state}, type) {

        const token = document.querySelector("meta[name='csrf-token']").getAttribute('content');

        function deleteFile() {
            if(!state[type].length) return;
            const id = state[type][0];
            axios.delete(`/file/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': token
                }
            }).then(res => {
                if (res.response?.data) {
                    commit('setError', res.response.data);
                } else {
                    commit('removeNewFile', id);
                    (type === 'selectedFiles') ? commit("removeSelectedFile", [id]) : commit("removeMissingFile", [id]);
                    deleteFile();
                }
            }).catch((error) => {
                commit('setError', error);
            });
        }

        deleteFile();
    },
    checkFile({state}, ids) {
        return new Promise((resolve, reject) => {
            axios.post(`/files/check.json`, {id: ids}, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token
                }
            }).then(res => {
                if(res.data === undefined) return reject('No data');
                resolve(res.data);
            });
        });
    },
    async addSelectedFile({commit, dispatch, state}, ids) {

        const data = await dispatch('checkFile', ids)

        for(let id of Object.keys(data)) {
            (data[id]) ? commit("addSelectedFile", parseInt(id)) : commit("addMissingFile", parseInt(id))
        }
    },
    removeSelectedFile({commit}, ids) {
        commit("removeSelectedFile", ids);
    },
    removeMissingFile({commit}, id) {
        commit("removeMissingFile", id);
    },
    resetSelectedFiles({commit}) {
        commit("resetSelection");
    },
    syncUserCart({commit}) {
        axios.get('/cart.json', {headers: {'Content-Type':'application/json'}}).then(res => {
            commit('syncUserCart', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        });
    },
    addToFileCart({commit, dispatch, state}, id) {
        if(state.fileCart.find(f => f.id === id)) return;
        commit("addToFileCart", id);
        dispatch('updateCart',  {type: 'file_cart', action: 'added'} );
    },
    removeFromFileCart({commit, dispatch}, id) {
        commit("removeFromFileCart", id);
        dispatch('updateCart',  {type: 'file_cart', action: 'removed'} );
    },
    addToFolderCart({commit, dispatch}, id) {
        if(state.folderCart.filter(f => f.id === id).length) return;
        commit("addToFolderCart", id);
        dispatch('updateCart',  {type: 'folder_cart', action: 'added'} );
    },
    removeFromFolderCart({commit, dispatch}, id) {
        commit("removeFromFolderCart", id);
        dispatch('updateCart',  {type: 'folder_cart', action: 'removed'} );
    },
    updateCart({commit, state}, {type, action}){
        const cart = (type === 'file_cart') ? state.fileCart : state.folderCart;
        const ids = cart.map(f => f.id);
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            type: type,
            id: ids,
        };
        if(state.postManager !== null) clearTimeout(state.postManager);
        state.postManager = setTimeout(() => {
            axios.post('/cart.json', params, {headers: {'Content-Type': 'application/json'}}).then(res => {
                if (res.data) commit('setAlertResponse', {type: 'successful', action: action, text: ` ${action === 'added' ? 'item(s) to' : '1 item from'} Cart`});
                state.postManager = null;
            });
        },1000);

        commit("resetSelection");
    },
    deleteCart({commit}) {
        axios.delete(`/cart.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token
            }
        }).then(res => {
            commit("resetFileCart");
            commit("resetFolderCart");
            commit("resetSelection");
            commit('setAlertResponse', {type: 'successful', action: 'reset', text: 'Cart'});
        }).catch((error) => {
            commit('setError', error);
        });
    },
    pasteFiles({commit, state}, {folder_id, file_ids}) {
        commit('loadingFilesToggle', true);
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            folder_id: folder_id,
            file_ids: file_ids,
            timespan: state.timespan,
        };
        axios.post('/files/move.json', params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            commit('setFiles', res.data);
            commit('loadingFilesToggle', false);
        });
    },
    rotatePreview({commit, state}, degree) {
        commit('loadingFilesToggle', true);
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            file_ids: state.selectedFiles,
            degree: degree,
        };
        axios.post('/files/rotate.json', params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            commit('updateFileThumbnails');
            commit('loadingFilesToggle', false);
        });
    },
    fetchFolderProperties({commit}, folder_id) {
        axios.get(`/folders/${folder_id}/properties.json`).then(res => {
            commit('setFolderProperties', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
    resetFolderProperties({commit}) {
        commit('resetFolderProperties');
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}