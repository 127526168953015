import axios from "axios";

const state = {
    pageTitle:'MediaGallery',
    navItems:[
        { title: 'Home', icon: 'mdi-view-dashboard', path:'/', name: 'MediaGallery', tooltip: 'Home' },
        { title: 'Projects', icon:'mdi-table', path:'/mg/list/projects', name: 'Projects', tooltip: 'Projects'},
        { title: 'Guidelines', icon: 'mdi-text-box-outline', path:'', name: 'Guidelines', tooltip: 'Guidelines' },
        { title: 'Tutorial', icon: 'mdi-lifebuoy', path:'/tutorial', name: 'Tutorial Videos', tooltip: 'Tutorial' },
    ],
    mgHelp: [
        {
            icon:'mdi-lifebuoy',
            name: 'Contact Us',
            to: '/mg/contact_us',
            newTab: false,
        },
        {
            icon:'mdi-bug',
            name:'Bug Report',
            to: 'mailto:helpdesk@international-testing.org?subject=MediaGallery%20Bug&body=Hello%20ICRT%20Team,%0A%0APlease,%20provide%20us%20with:%0A%0ADescription%20of%20the%20problem%0D%0ALink%20to%20the%20page%20%0D%0AScreenshots%20of%20the%20problem%20',
            newTab: true,
        },
        {
            icon:'mdi-format-list-bulleted',
            name: 'Changes',
            to: '/mg/welcome',
            newTab: false,
        },
    ],
    contactUsList: [
        {
            id: 2246,
            name: "ICRT Helpdesk",
            email: "helpdesk@international-testing.org",
            bio: "This account is used for dealing with any technical or IT issues related to ProjectLink, ProductTracker, MediaGallery,  EvalDB, or AccessFinancials. This account is monitored daily by the ICRT team and you should expect to get a response within 24 hours. In very urgent cases you can send an email to michael.grant@international-testing.org."
        },
        {
            id: 2242,
            name: "ICRT Invoicing",
            email: "invoice@international-testing.org",
            bio: "This account is used for dealing with Costshare or Invoicing related issues. This account is monitored daily by the ICRT team and you should expect to get a response within 24 hours. In very urgent cases you can send an email to michael.grant@international-testing.org."
        },
        {
            id: 2241,
            name: "ICRT Finance Team",
            email: "finance@international-testing.org",
            bio: "This account is used for dealing with any general finance related issues. This account is monitored daily by the ICRT team and you should expect to get a response within 24 hours. In very urgent cases you can send an email to michael.grant@international-testing.org."
        },

    ],
    icrtServicesList:[
        {
            icon:'projectlink',
            name: ["Project", "Link"],
            to: 'https://projectlink.international-testing.org/'
        },
        {
            icon: 'producttracker',
            name: ["Product", "Tracker"],
            to: 'https://producttracker.org/'
        },
        {
            icon: 'mediagallery',
            name: ["Media", "Gallery"],
            to: 'https://media.international-testing.org/'
        },
        {
            icon: 'accessfinancials',
            name: ["Access", "Financials"],
            to: 'https://evolutions.accessacloud.com/finops/Homepage.aspx?sid=5d8f4b50cff740afa6a626c7805cd56e'
        },
        {
            icon: 'evaldb',
            name: ["Eval", "DB"],
            to: 'https://evaldb4.international-testing.org/'
        },
        {
            icon: 'knowledgehub',
            name: ["Knowledge", "Hub"],
            to: 'https://knowledge.international-testing.org/login/index.php'
        },
        {
            icon: 'api',
            name: ["API", "Gateway"],
            to: 'https://api.icrt.io/'
        }
    ],
}
const getters = {
    getPageTitle: state => state.pageTitle,
    mgHelp: state => state.mgHelp,
    contactUsList: state => state.contactUsList,
    navItems: state => state.navItems,
    icrtServicesList: state => state.icrtServicesList,
}
const mutations = {
    setPageTitle: (state, data) => state.pageTitle = data,
}

const actions = {
    changePageTitle({commit}, title){
        commit('setPageTitle', title);
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
